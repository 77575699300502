<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            13. Незадымляемые лестничные клетки
          </v-card-title>
        </v-col>
        <v-col cols="12">
          <v-alert
            border="left"
            colored-border
            color="red"
            class="title font-weight-light pl-10"
            elevation="2"
          >
            <p class="pa-0 ma-0"><strong>Незадымляемая лестничная клетка</strong> конструкция в виде марша определенных геометрических размеров.</p>
            <p class="pa-0 ma-0">Устанавливается в
            <v-tooltip bottom color="red">
              <template v-slot:activator="{ on }">
                <span
                  class="blue lighten-4"
                  v-on="on"
                > многоэтажных объектах в обязательном порядке.</span>
              </template>
              <span class="pa-5 subtitle-1 pl-0">В зданиях всех классов функциональной пожарной опасности высотой более 28м<br> (за исключением зданий и сооружений класса Ф5 категории В, Д, без постоянного пребывания людей),<br> а также в зданиях класса Ф5 категории А и Б вне зависимости от высоты следует предусматривать <br>незадымляемые лестничные клетки, как правило, типа Н1.</span>
            </v-tooltip>
            </p>
            <p class="pa-0 ma-0">Должна предусматривать транспортирование эвакуированных с помощью носилок.</p>
          </v-alert>
        </v-col>
        <v-col cols="4">
          <v-card-title>
            Типы лестниц по способу<br> защиты от задымления:
          </v-card-title>
          <v-dialog
            v-model="dialog1"
            width="80%"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                color="red title mb-5"
                dark
                x-large
                v-bind="attrs"
                v-on="on"
              >
                H1
              </v-btn>
            </template>

            <v-card class="noselect">
              <v-card-title class="headline">
                H1
              </v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="8">
                    <v-card-text class="title font-weight-light">
                      <p class="pb-0 mb-2">С выходом на лестничную клетку с этажа через незадымляемую наружную воздушную зону по открытым переходам.</p>
                      <p class="pb-0 mb-2">Эта конструкция требует устройства лестниц, на которые можно попасть с площадок этажа через пространство с открытым воздухом.</p>
                      <p class="pb-0 mb-2">Применяется в административных, общественных и учебных заведениях, чью высота превышает 28 метров.</p>
                      <p class="pb-0 mb-2">Считается наиболее приспособленным для проведения эвакуации людей из здания, охваченного дымом.</p>
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Конструктивные особенности:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      наличие выхода с лестницы на улицу;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      не связаны напрямую с этажами здания;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      обычно располагаются в углах зданий и сооружений с наветренной стороны и имеют переходы балконного вида, огражденные защитными экранами.
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <p class="pb-0 mb-2">Переходы через наружную воздушную зону должны быть шириной не менее 1,2 м и высотой ограждения не менее 1,2 м.</p>
                      <p class="pb-0 mb-0">Ширина глухого простенка в наружной воздушной зоне между проемами лестничной клетки и проемами коридора этажа не менее 1,2м.</p>
                    </v-card-text>
                  </v-col>
                  <v-col class="ptm-h" cols="4">
                    <div class="d-flex justify-center">
                      <v-img
                        lazy-src="img/material/h1.png"
                        max-width="350"
                        min-width="350"
                        min-height="100%"
                        src="img/material/h1.png"
                        class="ma-1 "
                      ></v-img>
                    </div>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h1-1"
                        >
                          1
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">Воздушная зона</span>
                    </v-tooltip>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h1-2"
                        >
                          2
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">несгораемые двери (предел огнестойкости Е 130)</span>
                    </v-tooltip>
                    <v-sheet flat class="d-flex justify-center align-center mx-auto">
                      <v-card class="d-flex justify-center" flat>
                        <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
                        <v-card-text class="title font-weight-light">
                          Наводите на кнопки для изучения информации
                        </v-card-text>
                      </v-card>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog1 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog2"
            width="80%"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                color="red title mb-5"
                dark
                x-large
                v-bind="attrs"
                v-on="on"
              >
                H2
              </v-btn>
            </template>

            <v-card class="noselect">
              <v-card-title class="headline mb-0 pb-0">
                H2
              </v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="8">
                    <v-card-text class="title font-weight-light">
                      <p class="pb-0 mb-2">С подпором воздуха на лестничную клетку при пожаре. Применяются в зданиях с верхним этажом на высоте от 28 до 50 метров.</p>
                      <p class="pb-0 mb-2">Вход на лестничные марши устраивается через тамбуры или шлюзы, оборудованные противопожарными дверями.</p>
                      <p class="pb-0 mb-2">Целесообразно устраивать вертикальные перегородки через каждые 7-8 этажей.</p>
                      <p class="pb-0 mb-2">Подпор воздуха монтируется в верхние зоны получившихся отсеков. Воздушных подпор устраивается по принципу печной тяги.</p>
                      <p class="pb-0 mb-2">Может быть постоянным, или открывающимся во время пожарной тревоги.</p>
                    </v-card-text>

                    <v-card-title class="title red--text">
                      Стены клеток не должны иметь проемов, кроме:
                    </v-card-title>

                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      неоткрываемых оконных в наружных стенах;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      дверных, ведущих в помещения, коридоры, холлы и вестибюли, защищаемые вытяжной противодымной вентиляцией, или наружу;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      отверствий для подачи воздуха с целью создания избыточного давления.
                    </v-card-text>
                    <v-card-title class="title red--text">
                      Сила тяги (подпора) должна быть рассчитана, чтобы противопожарные двери на лестницу смог открыть любой человек:
                    </v-card-title>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      на нижнем этаже – не менее 20 Па;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      на верхнем – не более 150 Па.
                    </v-card-text>

                  </v-col>
                  <v-col class="ptm-h" cols="4">
                    <div class="d-flex justify-center">
                      <v-img
                        lazy-src="img/material/h3.png"
                        max-width="350"
                        min-width="350"
                        min-height="100%"
                        src="img/material/h2.png"
                        class="ma-1 "
                      ></v-img>
                    </div>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h2-1"
                        >
                          1
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">Противопожарная рассечка через каждые 5 этажей</span>
                    </v-tooltip>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h2-2"
                        >
                          2
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">Шахта дымоудаления</span>
                    </v-tooltip>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h2-3"
                        >
                          3
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">Несгораемые двери (предел огнестойкости Е 130)</span>
                    </v-tooltip>
                    <v-sheet flat class="d-flex justify-center align-center mx-auto">
                      <v-card class="d-flex justify-center" flat>
                        <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
                        <v-card-text class="title font-weight-light">
                          Наводите на кнопки для изучения информации
                        </v-card-text>
                      </v-card>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog2 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialog3"
            width="60%"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                color="red title mb-5"
                dark
                x-large
                v-bind="attrs"
                v-on="on"
              >
                H3
              </v-btn>
            </template>

            <v-card class="noselect">
              <v-card-title class="headline mb-0 pb-0">
                H3
              </v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="6">
                    <v-card-text class="title font-weight-light">
                      <p class="pb-0 mb-2">С входом на каждом этаже через тамбур-шлюз, в котором постоянно или во время пожара обеспечивается подпор воздуха.</p>
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <p class="pb-0 mb-2">Отличие от Н2 в устройстве помещений (не менее 4 м2) для прохода людей с самозакрывающимися дверями на доводчиках.</p>
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <p class="pb-0 mb-2">Подпор воздуха осуществляется как в занимаемое лестницей пространство, так и в шлюзы.</p>
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <p class="pb-0 mb-2">Может быть постоянным, или открывающимся во время пожарной тревоги.</p>
                    </v-card-text>
                  </v-col>
                  <v-col class="ptm-h" cols="6">
                    <div class="d-flex justify-center">
                      <v-img
                        lazy-src="img/material/h3.png"
                        max-width="350"
                        min-width="350"
                        min-height="100%"
                        src="img/material/h3.png"
                        class="ma-1 "
                      ></v-img>
                    </div>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h3-1"
                        >
                          1
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">Противопожарная рассечка через каждые 5 этажей</span>
                    </v-tooltip>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h3-2"
                        >
                          2
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">Шахта дымоудаления</span>
                    </v-tooltip>
                    <v-tooltip bottom color="red">
                      <template v-slot:activator="{ on }">
                        <v-btn
                        rounded
                        dark
                        color="red"
                        v-on="on"
                        class="mb-2 ptm-h3-3"
                        >
                          3
                        </v-btn>
                      </template>
                      <span class="pa-5 subtitle-1 ">Несгораемые двери (предел огнестойкости Е 130)</span>
                    </v-tooltip>
                    <v-sheet flat class="d-flex justify-center align-center mx-auto">
                      <v-card class="d-flex justify-center" flat>
                        <v-icon color="red" size="30">mdi-cursor-pointer</v-icon>
                        <v-card-text class="title font-weight-light">
                          Наводите на кнопки для изучения информации
                        </v-card-text>
                      </v-card>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog3 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="8">
          <v-card class="d-flex ">
          <div>
            <v-card-title >
              Основное требование:
            </v-card-title>
            <v-card-text class="title font-weight-light">
              Отсутствие в воздушной зоне дыма при пожаре. Это позволит:
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
              исключить гибель людей и их травматизм;
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
              обеспечить быструю и эффективную эвакуацию людей.
            </v-card-text>
          </div>
          <div class="pa-5 d-flex align-center">
            <v-img
              lazy-src="img/material/stairs.svg"
              max-width="150"
              src="img/material/stairs.svg"
              class="ma-1"
            ></v-img>
          </div>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card-text class="title font-weight-light">
            Тип лестничной клетки в конкретном здании определяется нормативными актами и строительными правилами.
          </v-card-text>
          <v-card-text class="title font-weight-light">
            Кроме конструкционных особенностей важно соблюдать требования СНиП к габаритам проходов и используемым материалам.
          </v-card-text>
          <v-card-text class="title red--text font-weight-bold">
            При наличии нарушений лестница не выполняет свои функции в полной мере им может быть опасной!
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-dialog
            v-model="dialog4"
            width="90%"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                color="red title mb-5"
                dark
                x-large
                v-bind="attrs"
                v-on="on"
                outlined
              >
                Дополнительные требования
              </v-btn>
            </template>

            <v-card class="noselect">
              <v-card-title class="headline">
                ТРЕБОВАНИЯ К НЕЗАДЫМЛЯЕМЫМ ЛЕСТНИЧНЫМ КЛЕТКАМ
              </v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-tabs grow v-model="tab" color="red">
                      <v-tab>Выход на лестницу</v-tab>
                      <v-tab>Требования к выходам на эвакуационную лестницу</v-tab>
                      <v-tab>Допустимые к использованию материалы</v-tab>
                      <v-tab>Требования к габаритам</v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12">
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-card-text class="title font-weight-light">
                          <p>Чтобы лестничная клетка оставалась незадымляемой в случае близкого расположения источника возгорания или при возникновении масштабного пожара, обеспечивают наличие безопасных подходов.</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p>Упор делают на противопожарные перегородки и специальные двери. Они изготавливаются из негорючих и нетоксичных материалов. Дополнительно обеспечивается герметизация всех стыков и проверяется степень сопротивляемости высоким температурам и открытому огню.</p>
                        </v-card-text>
                      </v-tab-item>
                      <v-tab-item>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Освещение</span> - Лестница должна быть оснащена достаточным количеством источников света и аварийным освещением. На лестничной клетке должны быть предусмотрены окна.
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Вентиляция</span> - Для подачи воздуха необходима вентиляционная шахта, связанная с маршами. На верхнем ярусе оставляется отверстие для обеспечения постоянной циркуляции воздуха. В некоторых случаях требуется принудительное обеспечение этого процесса с помощью вентиляционного оборудования.</p>
                          <p>Если незадымляемая клетка устраивается через стену с шахтой лифта, то в этой стене устраивается вентиляционное отверстие на уровне верхнего этажа.</p>
                        </v-card-text>

                        <v-card-text class="title font-weight-light">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Перегородки</span> - Дополнительные перегородки на пути к основной части здания препятствуют проникновению огня на территорию эвакуационного выхода. Между шахтой лифта и эвакуационным проходом должно сохраняться расстояние около 2 метров.</p>
                          <p class="mb-0 pb-0"><span class="title red--text font-weight-bold ">ЗАПРЕЩЕНО:</span></p>
                          <v-card-text class="title font-weight-light mb-0 pb-0">
                            <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                            самостоятельно монтировать не предусмотренные строительным проектом перегородки;
                          </v-card-text>
                          <v-card-text class="title font-weight-light mb-0 pb-0">
                            <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                            прорубать проходы в существующих противопожарных переборках.
                          </v-card-text>
                        </v-card-text>

                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Свободный доступ</span> - Проход должен оставаться открытым круглосуточно.</p>
                          <p class="mb-0 pb-0"><span class="title red--text font-weight-bold ">ЗАПРЕЩЕНО:</span></p>
                          <v-card-text class="title font-weight-light mb-0 pb-0">
                            <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                            перегораживать проход;
                          </v-card-text>
                          <v-card-text class="title font-weight-light mb-0 pb-0">
                            <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                            ставить на его площади предметы;
                          </v-card-text>
                          <v-card-text class="title font-weight-light mb-0 pb-0">
                            <v-icon color="red" class="mr-3">mdi-alert-outline</v-icon>
                            закрывать ведущие к выходу двери.
                          </v-card-text>
                        </v-card-text>

                        <v-card-text class="title font-weight-light">
                          <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Доступ информации</span> - Обязательным условием является наличие указателей эвакуационных выходов и плана эвакуации.
                        </v-card-text>

                      </v-tab-item>
                      <v-tab-item>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Высота</span> - Высота потолков в зоне расположения марша не менее 190 см.
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Ширина</span> - Размеры прохода - 120 см по ширине в воздушной зоне. Ширина пути к проходу не меньше 110 см. Должно обеспечиваться беспрепятственное движение 2 человек, а также перенос пострадавших на носилках.</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Количество ступеней</span> - В пределах одного марша допускается максимум 16-18 рядовых ступеней.</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Габариты ступеней</span> - Ширина - достаточно широкая для комфортного размещения на её поверхности стопы. Высота - в 1,5 раза меньше ширины. Все элементы марша должны быть одинаковыми по параметрам. Уклон варьируется от 30 до 40°.</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Количество людей</span> - Одновременное нахождение людей - около 15 человек. Точное значение зависит от габаритов здания и его назначения, типа лестничной конструкции.</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Поручни</span> - Лестничные марши должны быть оборудованы поручнями из негорючих и мало нагревающихся материалов.</p>
                        </v-card-text>

                      </v-tab-item>
                      <v-tab-item>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p>Незадымляемые лестницы изготавливают из материалов, которые максимально противостоят нагреву и не воспламеняются.</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p>Чаще всего используют:</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Металл</span> - Используется, когда нет возможности построить массивную конструкцию. Металлическими элементами укрепляется внутренняя структура маршей из бетона, обустраиваются ограждения.</p>
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <p><v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          <span class="title red--text font-weight-bold">Бетон</span> - Лидер по использованию, так как полностью пожаробезопасен. Марши из бетона удобные и прочные, соответствуют стандартам по габаритам и прочим требованиям СНиП. Бетон применяется для строительства внутренних эвакуационных конструкций.</p>
                        </v-card-text>

                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red"
                  text
                  @click="dialog4 = false"
                >
                  Закрыть
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div class="pa-5 d-flex justify-center align-center">
            <v-img
              lazy-src="img/material/ladder.svg"
              max-width="150"
              src="img/material/ladder.svg"
              class="ma-1"
            ></v-img>
          </div>
        </v-col>
        <Hint></Hint>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    dialog1: null,
    dialog2: null,
    dialog3: null,
    dialog4: null,
    tab: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptm-h {
  position: relative;
}
.ptm-h1-1 {
  position: absolute;
  top: 13%;
  right: 30%;
}
.ptm-h1-2 {
  position: absolute;
  top: 35%;
  right: 26%;
}
.ptm-h2-1 {
  position: absolute;
  top: 15%;
  right: 40%;
}
.ptm-h2-2 {
  position: absolute;
  top: 42%;
  right: 70%;
}
.ptm-h2-3 {
  position: absolute;
  top: 49%;
  right: 50%;
}
.ptm-h3-1 {
  position: absolute;
  top: 30%;
  right: 40%;
}
.ptm-h3-2 {
  position: absolute;
  top: 42%;
  right: 65%;
}
.ptm-h3-3 {
  position: absolute;
  top: 70%;
  right: 65%;
}
</style>
